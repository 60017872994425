<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">排程记录</div>
        <div class="contentTopRight">
          <el-tooltip v-premiSub="'排程记录_删除'" class="item" effect="dark" content="删除" placement="top">
            <img :src=delStatusImg alt="" @click="deleteFn" style="margin-right:0px">
          </el-tooltip>
          <el-input placeholder="排程流水号" suffix-icon="el-icon-search" size="small" v-model="input2"
            @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="Inuse" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="serialNumber" label="排程流水号">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column prop="createUser" label="创建人">
        </el-table-column>
        <el-table-column prop="scheduledStatus" label="状态">
          <template slot-scope="">
            <span>使用中</span>
          </template>
        </el-table-column>
        <el-table-column prop="serialNumber" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content">
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleTabs">
          <el-tab-pane label="未发布" name="Unpublished">
            <el-table ref="multipleTable" :data="Unpublished2" key="tableData" tooltip-effect="dark" style="width: 100%"
              @sort-change="sortChange" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="65">
              </el-table-column>
              <el-table-column prop="showId" label="序号" fixed="left" width="100">
              </el-table-column>
              <el-table-column prop="serialNumber" label="排程流水号" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createUser" label="创建人" sortable='custom'>
              </el-table-column>
              <el-table-column prop="scheduledStatus" label="状态">
                <template slot-scope="scope">
                  <span
                    :class="{ 'cell_Blue': (scope.row.scheduledStatus === 3), 'cell_Yellow': (scope.row.scheduledStatus === 1), 'cell_Green': (scope.row.scheduledStatus === 2) }"></span>
                  <span style="margin-left: 10px">未发布</span>
                </template>
              </el-table-column>
              <el-table-column prop="plan" label="" sortable='custom'>
                <template slot="header" slot-scope="{}">
                  <el-checkbox @change="checkedChange1" @click.stop.native="() => { }"
                    v-model="checkAll1">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="serialNumber" label="操作">
                <template slot-scope="scope">
                  <el-tooltip v-premiSub="'排程记录_编辑'" v-if="scope.row.scheduledStatus === 1" class="item" effect="dark"
                    content="编辑" placement="top">
                    <img src="@/assets/images/plan/analysis/edit.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-else class="item" effect="dark" content="查看详情" placement="top">
                    <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-premiSub="'排程记录_删除'" v-if="scope.row.scheduledStatus === 1" class="item" effect="dark"
                    content="删除" placement="top">
                    <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="delFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.hasLogs" class="item" effect="dark" content="日志" placement="top">
                    <img src="@/assets/images/plan/log.png" alt="" @click="logFn(scope.row)">
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="totalFlex">
              <span>共{{ total_1 }}条</span>
              <span>
                <el-pagination @size-change="handleSizeChange_1" background @current-change="handleCurrentChange_1"
                  :current-page="currentPage1_1" :page-sizes="[10, 20, 30, 40]" :page-size=pageChange_1
                  layout="prev, pager, next,sizes, jumper" :total=total_1>
                </el-pagination>
              </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已发布" name="Published">
            <el-table ref="multipleTable" :data="Published2" tooltip-effect="dark" style="width: 100%"
              @sort-change="sortChange" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="65">
              </el-table-column>
              <el-table-column prop="showId" label="序号" fixed="left" width="100">
              </el-table-column>
              <el-table-column prop="serialNumber" label="排程流水号" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" sortable='custom'>
              </el-table-column>
              <el-table-column prop="createUser" label="创建人" sortable='custom'>
              </el-table-column>
              <el-table-column prop="scheduledStatus" label="状态">
                <template slot-scope="scope">
                  <span
                    :class="{ 'cell_Blue': (scope.row.scheduledStatus === 3), 'cell_Yellow': (scope.row.scheduledStatus === 1), 'cell_Green': (scope.row.scheduledStatus === 2) }"></span>
                  <span style="margin-left: 10px">已发布</span>
                </template>
              </el-table-column>
              <el-table-column prop="plan" label="" sortable='custom'>
                <template slot="header" slot-scope="{}">
                  <el-checkbox @change="checkedChange2" @click.stop.native="() => { }"
                    v-model="checkAll2">计划分析</el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="serialNumber" label="操作">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                    <img src="@/assets/images/plan/examine.png" alt="" @click="editFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.scheduledStatus === 1" class="item" effect="dark" content="删除"
                    placement="top">
                    <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="delFn(scope.row)">
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.hasLogs" class="item" effect="dark" content="日志" placement="top">
                    <img src="@/assets/images/plan/log.png" alt="" @click="logFn(scope.row)">
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="totalFlex">
              <span>共{{ total_2 }}条</span>
              <span>
                <el-pagination @size-change="handleSizeChange_2" background @current-change="handleCurrentChange_2"
                  :current-page="currentPage1_2" :page-sizes="[10, 20, 30, 40]" :page-size=pageChange_2
                  layout="prev, pager, next,sizes, jumper" :total=total_2>
                </el-pagination>
              </span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ConfirmDel :dialogVisible="dialogVisible" @close="close" @ok="ok" />
  </div>
</template>

<script>
const ConfirmDel = () => import("@/components/ConfirmDel.vue")
import {
  getSchedulingData, delScheduling, searchSchedulingData, errorLog, getSerialNumberSortValue, getCreateTimeSortValue, getCreateUserSortValue,
  getPlanAnalysisStateSortValue, updateById, updateAllPlanAnalysisSelectState
} from '@/api/plan.js'
export default {
  name: "scheduling",
  components: {
    ConfirmDel
  },
  computed: {
    delStatusImg() {
      return this.bulkDeletionStatus ? require("@/assets/images/plan/analysis/delete.png") : require("@/assets/images/plan/analysis/bulkDeletion.png")
    }
  },
  data() {
    return {
      activeName: 'Unpublished',
      Inuse: [],  // 使用中
      Unpublished: [],  // 未发布
      Unpublished2: [],
      total_1: 0,
      currentPage1_1: 1,
      pageChange_1: 10,
      Published: [],  // 已发布
      Published2: [],
      total_2: 0,
      currentPage1_2: 1,
      pageChange_2: 10,
      bulkDeletionStatus: false,  // 多选删除的状态
      dialogVisible: false,
      tableData: [],
      total: 0,
      multipleSelection: [],
      input2: '',
      tempData: [],
      section: [
        '已确认',
        '未确认'
      ],
      tempTotal: 0,
      searchData: [],
      temp: 0,
      checkAll1: false,
      checkAll2: false,
      checkLen1: 0,
      checkLen2: 0,
      status: 1,  // 标签页切换，1为未发布，3为已发布
      sortStatus: 0,  // 排序状态 ascending为1，descending为2
      sortText: '',
      searchStatus: false
    }
  },
  methods: {
    async checkedChange1(val) {
      let res = null
      if (val) {
        res = await updateAllPlanAnalysisSelectState(1, 1)
      } else {
        res = await updateAllPlanAnalysisSelectState(0, 1)
      }
      if (res.code == 0) {
        await this.getData(this.currentPage1_1, this.pageChange_1, 1)
        this.isAllSelect(true)
      } else {
        this.$message.warning(res.msg)
      }
    },
    async checkedChange2(val) {
      let res = null
      if (val) {
        res = await updateAllPlanAnalysisSelectState(1, 3)
      } else {
        res = await updateAllPlanAnalysisSelectState(0, 3)
      }
      if (res.code == 0) {
        await this.getData(this.currentPage1_2, this.pageChange_2, 3)
        this.isAllSelect(true)
      } else {
        this.$message.warning(res.msg)
      }
    },
    async sortChange(column) {
      let res = null
      let num = 0
      if (column.order === "ascending") {
        num = 1
      } else if (column.order === "descending") {
        num = 2
      } else {
        num = 0
        // 退出排序状态
        if (this.status == 1) {
          await this.getData(this.currentPage1_1, this.pageChange_1, 1)
          this.Unpublished2.forEach(v => {
            if (v.planAnalysisState == 0) {
              this.$set(v, 'checked', false)
            } else {
              this.$set(v, 'checked', true)
            }
          })
        } else if (this.status == 3) {
          await this.getData(this.currentPage1_2, this.pageChange_2, 3)
          this.Published2.forEach(v => {
            if (v.planAnalysisState == 0) {
              this.$set(v, 'checked', false)
            } else {
              this.$set(v, 'checked', true)
            }
          })
        }
        this.sortText = ''
        this.sortStatus = 0
        return
      }
      this.sortStatus = num
      this.$nextTick(async () => {
        let s1 = document.querySelector('.ascending.is-sortable div')
        let s2 = document.querySelector('.descending.is-sortable div')
        if (s1) {
          if (s1.textContent == '排程流水号') {
            res = await getSerialNumberSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getSerialNumberSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s1.textContent == '创建时间') {
            res = await getCreateTimeSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateTimeSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s1.textContent == '创建人') {
            res = await getCreateUserSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateUserSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s1.textContent == '计划分析') {
            res = await getPlanAnalysisStateSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}` == 1 ? 2 : `${num}` == 2 ? 1 : 0)
            if (res.code == 0) {
              this.sortStatus = num
              this.sortText = 'getPlanAnalysisStateSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          }
        } else if (s2) {
          if (s2.textContent == '排程流水号') {
            res = await getSerialNumberSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getSerialNumberSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s2.textContent == '创建时间') {
            res = await getCreateTimeSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateTimeSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s2.textContent == '创建人') {
            res = await getCreateUserSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateUserSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (s2.textContent == '计划分析') {
            res = await getPlanAnalysisStateSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}` == 1 ? 2 : `${num}` == 2 ? 1 : 0)
            if (res.code == 0) {
              this.sortStatus = num
              this.sortText = 'getPlanAnalysisStateSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          }
        } else {
          if (column.prop === 'serialNumber') {
            res = await getSerialNumberSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getSerialNumberSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (column.prop === "createTime") {
            res = await getCreateTimeSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateTimeSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (column.prop === "createUser") {
            res = await getCreateUserSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}`)
            if (res.code == 0) {
              this.sortText = 'getCreateUserSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          } else if (column.prop === "plan") {
            res = await getPlanAnalysisStateSortValue(this.status === 1 ? this.currentPage1_1 : this.currentPage1_2, this.status === 1 ? this.pageChange_1 : this.pageChange_2, `${this.status}`, `${num}` == 1 ? 2 : `${num}` == 2 ? 1 : 0)
            if (res.code == 0) {
              this.sortStatus = num
              this.sortText = 'getPlanAnalysisStateSortValue'
            } else {
              this.$message.warning(res.msg)
            }
          }
        }
        if (this.status === 1) this.Unpublished2 = res?.data.records
        if (this.status === 3) this.Published2 = res?.data.records
        // 如果planAnalysisState为0，全部$set为false
        this.Unpublished2?.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
        this.Published2?.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
      })
    },
    async sameFn() {
      if (this.sortStatus !== 0) {
        let res = null
        if (this.sortText === 'getSerialNumberSortValue') {
          res = await getSerialNumberSortValue(this.currentPage1_1, this.pageChange_1, this.status, this.sortStatus)
        }
        if (this.sortText === 'getCreateTimeSortValue') {
          res = await getCreateTimeSortValue(this.currentPage1_1, this.pageChange_1, this.status, this.sortStatus)
        }
        if (this.sortText === 'getCreateUserSortValue') {
          res = await getCreateUserSortValue(this.currentPage1_1, this.pageChange_1, this.status, this.sortStatus)
        }
        if (this.sortText === 'getPlanAnalysisStateSortValue') {
          res = await getPlanAnalysisStateSortValue(this.currentPage1_1, this.pageChange_1, this.status, `${this.sortStatus}` == 1 ? 2 : `${this.sortStatus}` == 2 ? 1 : 0)
        }
        const { records, total } = res.data
        if (res.code == 0) {
          this.Unpublished2 = records
          this.total_1 = total
          this.Unpublished2.forEach(v => {
            if (v.planAnalysisState == 0) {
              this.$set(v, 'checked', false)
            } else {
              this.$set(v, 'checked', true)
            }
          })
        } else {
          this.$message.warning(res.msg)
        }
      } else {
        await this.getData(this.currentPage1_1, this.pageChange_1, 1)
      }
    },
    async sameFn2() {
      if (this.sortStatus !== 0) {
        let res = null
        if (this.sortText === 'getSerialNumberSortValue') {
          res = await getSerialNumberSortValue(this.currentPage1_2, this.pageChange_2, this.status, this.sortStatus)
        }
        if (this.sortText === 'getCreateTimeSortValue') {
          res = await getCreateTimeSortValue(this.currentPage1_2, this.pageChange_2, this.status, this.sortStatus)
        }
        if (this.sortText === 'getCreateUserSortValue') {
          res = await getCreateUserSortValue(this.currentPage1_2, this.pageChange_2, this.status, this.sortStatus)
        }
        if (this.sortText === 'getPlanAnalysisStateSortValue') {
          res = await getPlanAnalysisStateSortValue(this.currentPage1_2, this.pageChange_2, this.status, `${this.sortStatus}` == 1 ? 2 : `${this.sortStatus}` == 2 ? 1 : 0)
        }
        const { records, total } = res.data
        if (res.code == 0) {
          this.Published2 = records
          this.total_2 = total
          this.Published2.forEach(v => {
            if (v.planAnalysisState == 0) {
              this.$set(v, 'checked', false)
            } else {
              this.$set(v, 'checked', true)
            }
          })
        } else {
          this.$message.warning(res.msg)
        }
      } else {
        await this.getData(this.currentPage1_2, this.pageChange_2, 3)
      }
    },
    async handleSizeChange_1(val) {
      this.pageChange_1 = val
      this.currentPage1_1 = 1
      this.sameFn()
    },
    async handleSizeChange_2(val) {
      this.pageChange_2 = val
      this.currentPage1_2 = 1
      this.sameFn2()
    },
    async handleCurrentChange_1(val) {
      // 点击页数1、2、3……
      this.currentPage1_1 = val
      this.sameFn()
    },
    async handleCurrentChange_2(val) {
      this.currentPage1_2 = val
      this.sameFn2()
    },
    handleTabs(val) {
      if (val.paneName === 'Published') this.status = 3
      if (val.paneName === 'Unpublished') this.status = 1
    },
    // 跳转甘特图
    editFn(mark) {
      const { serialNumber, scheduledStatus, createUser } = mark
      this.$router.push({
        path: '/gantt',
        query: { serialNumber, scheduledStatus, createUser }
      })
    },
    // 多选删除
    async deleteFn() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择需要删除的排程记录！')
        return
      }
      if (this.bulkDeletionStatus === false) {
        this.$message.warning('选项中包含不允许删除的排程！')
        return
      }
      this.dialogVisible = true
    },
    // 单个删除
    delFn(mark) {
      this.delId = mark.id
      if (this.multipleSelection.length > 1) {
        this.$message.warning('请点击批量删除按钮')
        return
      }
      this.dialogVisible = true
    },
    // 下载错误日志
    async logFn(val) {
      if (val.hasLogs) {
        const res = await errorLog(val.serialNumber)
        this.filename = '排程记录错误日志.xlsx'
        this.url = window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.url
        link.setAttribute('download', this.filename)
        document.documentElement.appendChild(link)
        link.click()
        document.documentElement.removeChild(link)
      }
    },
    close() {
      this.dialogVisible = false
    },
    async ok() {
      this.dialogVisible = false
      let id = this.delId
      let delIdArr = []
      this.multipleSelection.forEach(v => delIdArr.push(v.id))
      if (delIdArr.length === 0) delIdArr.push(id)
      const res = await delScheduling(delIdArr)
      if (res.code === 0) {
        this.$message.success('删除成功！')
      } else {
        this.$message.error(res.msg)
      }
      this.input2 = ''
    },
    // 多选框
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (val.length === 0) this.bulkDeletionStatus = false
      for (let i = 0; i < val.length; i++) {
        if (val[i].scheduledStatus === 2 || val[i].scheduledStatus === 3) {
          this.bulkDeletionStatus = false
          return
        }
        if (val[i].scheduledStatus === 1) this.bulkDeletionStatus = true
      }
    },
    async searchHandler() {
      let res = null
      if (this.status == 1) {
        res = await searchSchedulingData(this.input2, this.currentPage1_1, 10, 1)
      } else {
        res = await searchSchedulingData(this.input2, this.currentPage1_2, 10, 3)
      }
      this.searchData = res.data.records
      this.filterFn(this.searchData)
      if (this.input2.length > 0) {
        this.searchStatus = true
      }
      if (this.input2.length == 0) {
        this.searchStatus = false
      }
      this.total_1 = res?.data.total
      this.total_2 = res?.data.total
    },
    async getData(page, size, filter) {
      const res = await getSchedulingData(page, size, filter)
      const { records, total } = res.data
      if (filter === 1) {
        // 未发布
        this.Unpublished2 = records
        this.total_1 = total
        this.Unpublished2.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
      } else if (filter === 2) {
        if (records.length > 0) this.Inuse.push(records[0])
      } else if (filter === 3) {
        this.Published2 = records
        this.total_2 = total
        this.Published2.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
      }
    },
    filterFn(list) {
      // 进行筛选，分别放到不同区域（未发布、已发布、使用中）
      let InuseFilter = []
      let status1 = false
      let status2 = false
      list.forEach(v => {
        if (v.scheduledStatus == 2) InuseFilter.push(v)
        if (v.scheduledStatus == 1) status1 = true
        if (v.scheduledStatus == 3) status2 = true
      })
      if (InuseFilter.length !== 0) this.Inuse.push(InuseFilter[0])
      if (status1) {
        this.Unpublished2 = list.filter(v => v.scheduledStatus == 1)
        if (!status2) this.Published2 = []
        this.total_1 = this.Unpublished2.length
        this.Unpublished2.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
      }
      if (status2) {
        this.Published2 = list.filter(v => v.scheduledStatus == 3)
        if (!status1) this.Unpublished2 = []
        this.total_2 = this.Published2.length
        this.Published2.forEach(v => {
          if (v.planAnalysisState == 0) {
            this.$set(v, 'checked', false)
          } else {
            this.$set(v, 'checked', true)
          }
        })
      }
    },
    async checked(val) {
      // console.log('--val--', val)
      if (val.checked) {
        const idRes = await updateById({ id: val.id, planAnalysisState: 1 })
        if (idRes.code != 0) {
          this.$message.warning(idRes.msg)
          return
        } else if (idRes.code == 0) {
          sessionStorage.setItem('mark', true)
          if (this.searchStatus) return
          if (this.status == 1) {
            await this.getData(this.currentPage1_1, this.pageChange_1, 1)
            this.Unpublished2.forEach(v => {
              if (v.planAnalysisState == 0) {
                this.$set(v, 'checked', false)
              } else {
                this.$set(v, 'checked', true)
              }
            })
          } else if (this.status == 3) {
            await this.getData(this.currentPage1_2, this.pageChange_2, 3)
            this.Published2.forEach(v => {
              if (v.planAnalysisState == 0) {
                this.$set(v, 'checked', false)
              } else {
                this.$set(v, 'checked', true)
              }
            })
          }
          if (this.sortStatus == 1) {
            this.sortChange({ order: 'ascending', prop: 'plan' })
          } else if (this.sortStatus == 2) {
            this.sortChange({ order: 'descending', prop: 'plan' })
          }
        }
      } else if (!val.checked) {
        const idRes = await updateById({ id: val.id, planAnalysisState: 0 })
        if (idRes.code != 0) {
          this.$message.warning(idRes.msg)
          return
        } else if (idRes.code == 0) {
          sessionStorage.setItem('mark', true)
          if (this.searchStatus) return
          if (this.status == 1) {
            await this.getData(this.currentPage1_1, this.pageChange_1, 1)
            this.Unpublished2.forEach(v => {
              if (v.planAnalysisState == 0) {
                this.$set(v, 'checked', false)
              } else {
                this.$set(v, 'checked', true)
              }
            })
          } else if (this.status == 3) {
            await this.getData(this.currentPage1_2, this.pageChange_2, 3)
            this.Published2.forEach(v => {
              if (v.planAnalysisState == 0) {
                this.$set(v, 'checked', false)
              } else {
                this.$set(v, 'checked', true)
              }
            })
          }
          if (this.sortStatus == 1) {
            this.sortChange({ order: 'ascending', prop: 'plan' })
          } else if (this.sortStatus == 2) {
            this.sortChange({ order: 'descending', prop: 'plan' })
          }
        }
      }
      // 勾选的数量是否与全部数量相等
      this.isAllSelect(false)
    },
    isAllSelect(flag) {
      if (flag) sessionStorage.setItem('mark', true)
      this.Unpublished2[0].isAllSelected === 1 ? this.checkAll1 = true : this.checkAll1 = false
      this.Published2[0].isAllSelected === 1 ? this.checkAll2 = true : this.checkAll2 = false
    }
  },
  async created() {
    await this.getData(1, 10, 1)  // 获取未发布数据
    await this.getData(1, 10, 2)  // 获取使用中数据
    await this.getData(1, 10, 3)  // 获取已发布数据
    this.input2 = ''
    this.isAllSelect(false)
  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>
